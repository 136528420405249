<template>
    <v-container>
        <v-card flat>
            <v-card-title primary-title class="letter-spacing">
                Uczniowie
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    name="search"
                    label="Wyszukaj ucznia"
                    outlined
                    dense
                    single-line
                    hide-details
                    clearable
                    clear-icon="fas fa-times"
                ></v-text-field>
                <v-btn color="primary" elevation="0" small outlined @click="creating = true" class="ml-2" height="40px">
                    <v-icon left size="14">
                        fas fa-plus
                    </v-icon>
                    Dodaj ucznia
                </v-btn>
            </v-card-title>
            <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :search="search"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                        :loading="loading"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="blue" class="blue lighten-5 table-icon" icon @click="osiagniecia.uczen = item; osiagniecia.display = true;">
                                <v-icon small>fas fa-award</v-icon>
                            </v-btn>
                            <v-btn color="blue" class="blue lighten-5 table-icon ml-2" icon @click="editing.uczen = item; editing.display = true;">
                                <v-icon small>fas fa-pencil-alt</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
            </v-card-text>
            <createModal :display="creating" @close="creating = false" @created="fetchAll"/>
            <editModal :display="editing.display" :uczen="editing.uczen" @updated="fetchAll" @close="editing.uczen = false; editing.display = false;"/>
            <osiagnieciaModal :display="osiagniecia.display" :uczen="osiagniecia.uczen"  @close="osiagniecia.display = false; osiagniecia.uczen = false" />
        </v-card>
    </v-container>
</template>

<script>
import createModal from '@/components/uczniowie/createModal.vue';
import editModal from '@/components/uczniowie/editModal.vue';
import osiagnieciaModal from '@/components/uczniowie/osiagnieciaModal.vue';

export default {
    components:{
        createModal,
        editModal,
        osiagnieciaModal
    },
    data:() => ({
        loading: false,
        creating: false,
        osiagniecia: {
            uczen: false,
            display: false,
        },
        editing: {
            display: false,
            uczen: false
        },
        search: '',
        headers:[
            { text: 'Lp.', value: 'id',  align: 'start'},
            { text: 'Imię', value: 'imie' },
            { text: 'Nazwisko', value: 'nazwisko' },
            { text: 'Wychowawca', value: 'wychowawca' },
            { text: 'Trener', value: 'trener' },
            { text: 'Zarządzaj', value: 'actions', align: 'end', sortable: false},
        ],
        items:[
        ],
    }),
    methods:{
        fetchAll: function(){
            this.$http.get('/uczniowie/getList')
                .then(res =>{
                    this.items = res.data.body;
                })
                .catch(err => {
                    console.log(err.response);
                })
                .finally(() => {
                    this.loading = false;
                })
        }
    },
    created(){
        let search = localStorage.getItem('uczniowieSearchCache');
        this.search = search!='null'?search:'';
        this.fetchAll();
    },
    destroyed(){
        localStorage.setItem('uczniowieSearchCache', this.search);
    }
}
</script>

<style>

</style>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
  >
       <v-card>
           <v-card-title primary-title class="letter-spacing">
               Edytowanie ucznia
           </v-card-title>
           <v-card-text class="py-2">
               <v-form v-model="valid">
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="form.imie"
                                name="name"
                                label="Imię"
                                counter="20"
                                dense
                                outlined
                                :error-messages="errors.imie"
                            ></v-text-field>
                       </v-col>
                   </v-row>
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0">
                            <v-text-field
                                v-model="form.nazwisko"
                                name="name"
                                label="Nazwisko"
                                counter="40"
                                dense
                                outlined
                                :error-messages="errors.nazwisko"
                            ></v-text-field>
                       </v-col>
                   </v-row>
                   <v-row no-gutters>
                       <v-col cols="12" class="py-0 ">
                           <v-select
                                :items="wychowawcy"
                                v-model="form.wychowawca"
                                label="Wychowawca"
                                outlined
                                dense
                                :error-messages="errors.wychowawca"
                           ></v-select>
                       </v-col>
                   </v-row>
                    <v-row no-gutters>
                       <v-col cols="12" class="py-0 ">
                           <v-select
                                :items="wychowawcy"
                                v-model="form.trener"
                                label="Trener"
                                outlined
                                dense
                                :error-messages="errors.trener"
                           ></v-select>
                       </v-col>
                   </v-row>
               </v-form>
           </v-card-text>
           <v-card-actions class="pt-0">
               <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="grey"
                        class="text-none letter-spacing"
                        @click="dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="primary"
                        class="text-none"
                        @click="sendForm"
                    >
                        Dodaj
                    </v-btn>
           </v-card-actions>

       </v-card>
  </v-dialog>
</template>

<script>
import dialog from '@/mixins/dialog.js';

export default {
    mixins: [dialog],
    props:{
        uczen:{
            required: true,
        }
    },
    data: () => ({
        valid: false,
        menu: false,
        form: {
            imie: '',
            nazwisko: '',
            wychowawca: '',
            trener: '',
        },
        errors:{
            imie: '',
            nazwisko: '',
            wychowawca: '',
            trener: '',
        },
        wychowawcy:[
            {'text': 'Brak wychowacy', value: -1}
        ]
    }),
    watch:{
          uczen(val){
            if(val){
                this.form.imie = val.imie;
                this.form.nazwisko = val.nazwisko;
                this.form.wychowawca = val.id_wychowawca;
                this.form.trener = val.id_trener;
                console.log(val);
            }
        }
    },
    methods:{
        sendForm: function(){
            this.$http.post('/uczniowie/update', {
                    id: this.$props.uczen.id,
                    imie: this.form.imie,
                    nazwisko: this.form.nazwisko,
                    wychowawca: this.form.wychowawca,
                    trener: this.form.trener
                })
                .then(() => {
                    this.dialog = false;
                    this.$emit('updated');
                })
                .catch(err => {
                    this.errors = err.response.data.body;
                })
        },
        fetchWychowacy: function(){
             this.$http.get('/nauczyciele/selectList')
                .then(res => {
                    this.wychowawcy = res.data.body;
                })
        },
        clearForm: function(){
            this.form = {
                imie: '',
                nazwisko: '',
                wychowawca: '',
                trener: '',
            };
            this.errors = {
                imie: '',
                nazwisko: '',
                wychowawca: '',
                trener: '',
            };
        }
    },
    created(){
        this.fetchWychowacy();
    }
}
</script>

<style>

</style>
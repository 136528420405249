<template>
  <v-dialog
      v-model="dialog"
      max-width="1000px"
      transition="dialog-transition"
  >
       <v-card>
            <v-card-title primary-title class="letter-spacing">
                Osiągnięcia ucznia:  <span v-if="item" class="ml-3"> {{item.imie}} {{item.nazwisko}}</span>
            </v-card-title>
            <v-card-text class="py-2">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                >
                    <template v-slot:[`item.name`]="{ item }">
                         <router-link :to="'/zawody/'+ item.zawody_id">{{item.name}}</router-link>
                    </template>
                    <template v-slot:[`item.miejsce`]="{ item }">
                         {{item.miejsce}} miejsce <v-icon class="ml-2" small :class="medalColor(item.miejsce)">fas fa-medal</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
       </v-card>
  </v-dialog>
</template>

<script>
import dialog from '@/mixins/dialog.js';

export default {
    mixins: [dialog],
    props:{
        uczen:{
            required: true
        }
    },
    data: () => ({
        item: false,
        headers:[
            { text: 'Zawody', value: 'name' },
            { text: 'Data zawodów', value: 'date'},
            { text: 'Nazwa dyscypliny', value: 'dyscyplina'},
            { text: 'Osiągnięte miejsce', value: 'miejsce', align: 'end'},
        ],
        items: []
    }),
    watch:{
        uczen(val){
            if(val) this.item = val;
            else {
                this.$emit('close');
                this.item = false;
            }
        },
        item(val){
            this.fetchOsiagniecia(val.id);
        }
    },
    methods:{
        fetchOsiagniecia: function(id){
            this.$http.get('/osiagniecia/getList', {params: {uczen: id}})
                .then(res => {
                    this.items = res.data.body;
                })
        },
        clearForm: function(){
        },
        medalColor: function(miejsce){
            if(miejsce == 1) return "yellow--text text--darken-2";
            else if(miejsce == 2) return 'grey--text text--lighten-1';
            else if(miejsce == 3) return 'brown--text';
            else return 'blue--text text--lighten-4';
        }
    },
    created(){
    }
}
</script>

<style>

</style>